import http from '../clients/Axios'

export default class DepartemenService {
  async paginate(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    div_id = 0,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField
    let filters_param = `page=${pageNumber}&size=${pageSize}&sort=${sort}`
    if (div_id > 0) {
      filters_param = filters_param + '&div_id=' + div_id
    }

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'dept' || el === 'name') {
            filters_param = filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(`/department/paginate?${filters_param}`)

    return res.data
  }

  async get() {
    const res = await http.get('/department?sort=name')
    return res.data
  }

  async getByDiv(div_id) {
    let res
    if (div_id == 0) {
      res = await http.get('/department?sort=name')
    } else {
      res = await http.get(`/department?div_id=${div_id}&sort=name`)
    }
    return res.data
  }

  async getBy(param) {
    param = param ? param : ''
    const res = await http.get(`/department${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/department', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/department/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/department/${id}`)
    return res
  }
}
