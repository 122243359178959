<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Divisi</label>
              <Dropdown
                v-model="form.div_id"
                :filter="true"
                :options="listDivisi"
                class="w-full"
                :class="{ 'p-invalid': v$.form.div_id.$invalid && submitted }"
                input-class="w-full"
                optionValue="id"
                optionLabel="name"
                @filter="onFilterDivisi"
              />
              <small
                v-if="
                  (v$.form.div_id.$invalid && submitted) ||
                  v$.form.div_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.div_id.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Kode Departemen (Opsional)</label>
              <InputText
                v-model="form.dept"
                type="text"
                class="w-full"
                input-class="w-full"
                maxlength="3"
              />
              <small
                v-if="
                  this.editMode == true &&
                  (this.form.dept == '' || this.form.dept == null)
                "
                class="p-error"
                >Kode departemen tidak boleh kosong</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Nama Departemen</label>
              <InputText
                v-model="form.name"
                :class="{ 'p-invalid': v$.form.name.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
                maxlength="40"
              />
              <small
                v-if="
                  (v$.form.name.$invalid && submitted) ||
                  v$.form.name.$pending.$response
                "
                class="p-error"
                >{{ v$.form.name.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-6">
              <label>STD Margin</label>
              <div
                class="p-inputgroup col-12 md:col-12"
                style="margin-left: -10px"
              >
                <InputNumber
                  v-model="form.std_mrg"
                  :class="{
                    'p-invalid': v$.form.std_mrg.$invalid && submitted,
                  }"
                  :minFractionDigits="0"
                  :maxFractionDigits="2"
                  locale="id-ID"
                  :min="1"
                  :max="100"
                  class="w-full"
                />
                <span class="p-inputgroup-addon">%</span>
              </div>
              <small
                v-if="
                  (v$.form.std_mrg.$invalid && submitted) ||
                  v$.form.std_mrg.$pending.$response
                "
                class="p-error"
                >{{ v$.form.std_mrg.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, numeric, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listDivisi: {
      type: Array,
      default() {
        return []
      },
    },
    divisiId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
    // getDivisi() {
    //   const divisi = this.listDivisi.find((a) => a.id === this.divisiId)
    //   if (divisi) {
    //     return divisi.name
    //   }
    //   return ''
    // },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (
        this.editMode == true &&
        (this.form.dept == '' || this.form.dept == null)
      ) {
        return
      }

      if (!isFormValid) {
        return
      }

      this.$emit('save', this.form)
    },
    onFilterDivisi() {
      this.form.div_id = null
    },
  },
  validations() {
    return {
      form: {
        div_id: {
          required: helpers.withMessage('Divisi harus dipilih.', required),
        },
        name: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        std_mrg: {
          required: helpers.withMessage(
            'STD Margin harus diisi dengan angka',
            required
          ),
          numeric,
        },
      },
    }
  },
}
</script>
