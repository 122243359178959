<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Departemen</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data Departemen</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button icon="pi pi-plus" label="Departemen" @click="onAddData" />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="divisi"
            header="Divisi"
            filterField="div_id"
            style="min-width: 15rem"
            :showFilterMenu="false"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="list_divisi"
                :filter="true"
                optionValue="id"
                optionLabel="name"
                placeholder="Pilih Divisi"
                class="p-column-filter"
                :showClear="true"
              >
              </Dropdown>
            </template>
          </Column>
          <Column
            field="dept"
            header="Kode Departemen"
            style="min-width: 12rem"
            sortable
            :filter-match-mode-options="equalModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="name"
            header="Nama Departemen"
            style="min-width: 15rem"
            sortable
            :filter-match-mode-options="containModesOption"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="std_mrg"
            header="Std Margin"
            style="min-width: 12rem; justify-content: flex-end"
          >
            <template #body="{ data }"> {{ data.std_mrg }}% </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit Departemen' : 'Tambah Departemen'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-departemen
        :item="form"
        :divisi-id="div_id"
        :loading="isLoadingSave"
        :departemen="departemen"
        :list-divisi="list_divisi"
        :editMode="editMode"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Departemen"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Departemen <strong>{{ form.name }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteDepartemen"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import DivisiService from '@/services/DivisiService'
import DepartemenService from '@/services/DepartemenService'
import FormDepartemen from '@/components/master/FormDepartemen'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'

export default {
  name: 'Departemen',
  components: {
    FormDepartemen,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      div_id: 0,
      departemenService: null,
      divisiService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      list_divisi: [],
      items: [],
      departemen: [],
      totalRecords: 0,
      form: {
        id: 0,
        div_id: 0,
        div: '',
        name: null,
        std_mrg: null,
        dept: null,
      },
      options: {
        page: 1,
        rows: 10,
        sortField: 'divisi,dept,name',
        sortOrder: '1',
        filters: null,
      },
      filters: {
        div_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        dept: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
      /* selectedColumns: null,
      columns: null, */
    }
  },
  created() {
    this.departemenService = new DepartemenService()
    this.divisiService = new DivisiService()
  },
  async mounted() {
    const divisiService = new DivisiService()
    await divisiService
      .get()
      .then((res) => {
        this.list_divisi = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Divisi', this)
      })
    this.loadData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.departemenService
        .paginate(page, perPage, sortField, sortOrder, this.div_id, filters)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data departemen: loadData', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.editMode = true
      this.form.id = data.id
      this.form.div_id = data.div_id
      this.form.dept = data.dept
      this.form.name = data.name
      this.form.std_mrg = data.std_mrg
      this.dialog = true
    },
    onAddData() {
      this.editMode = false
      this.form.id = 0
      this.form.div_id = null
      this.form.id = 0
      this.form.dept = null
      this.form.name = null
      this.form.std_mrg = 0
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      let item = {}
      item.div_id = data.div_id
      item.dept = data.dept === null ? '' : data.dept
      item.name = data.name
      item.std_mrg = data.std_mrg

      if (data.id == 0) {
        this.departemenService
          .add(item)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data departemen',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data departemen', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.departemenService
          .update(data.id, item)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data departemen',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data departemen', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteDepartemen() {
      this.departemenService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data departemen',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.dialogHapus = false
          }
          this.loadData()
        })
        .catch((err) => {
          errorHandler(err, 'Data Departemen', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
}
</script>
